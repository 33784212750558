@import "styles/utils/mixins";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top: 1px solid #dfe0e7;
}

.title {
  font-size: 88px;
  line-height: 96px;
  font-family: "TT Interfaces", sans-serif;
  font-weight: 700;
}

.description {
  max-width: 470px;
}

.top {
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &-navigation {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.navigation-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 470px;
  width: 100%;
  margin-left: auto;
  margin-top: 50px;
}

.pagination {
  width: 60px;
  height: 17px;
  display: flex;
  justify-content: space-between;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #8b8b95;
}

.slider-navigation {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
  width: 100%;
  margin-right: 30px;
}

.navigation {
  position: relative;
  cursor: pointer;
  max-width: 124px;

  & span {
    width: 100%;
  }

  & path {
    fill: #8b8b95;
    transition: all 0.3s ease-in;
  }

  &-prev,
  &-next {
    &::before {
      content: "";
      opacity: 0;
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid transparent;
      top: -25px;
      transition: all 0.3s ease-in;
    }
  }

  &-prev {
    &::before {
      left: -30px;
    }
  }

  &-next {
    &::before {
      right: -30px;
    }
  }

  &:hover::before {
    opacity: 1;
    border: 1px solid #ce1050;
  }

  &:hover path {
    fill: #ce1050;
  }
}
