@import "styles/utils/mixins";

.item.slide {
  width: 100%;
  height: 100%;
  border-right: 1px solid #dfe0e7;
  border-top: 1px solid #dfe0e7;
  border-bottom: 1px solid #dfe0e7;
}

.slider {
  margin-top: 90px;
}
